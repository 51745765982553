<template>
  <div class="page">
    <div class="page_body">
      <div class="account">
        <p>提现记录</p>
        <!-- <span>可累计返利：{{180}} 元，已累计提现：20 元</span> -->
      </div>
      <div class="gray"></div>
      <van-tabs v-model="tabIndex" @click="change_tab()">
        <van-tab :title="item.title" :name="index" v-for="(item,index) in tab" :key="index">
          <div class="gray"></div>
          <div v-if="withdrawIsNotNull">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list
                @load="_getData()"
                v-model="loading"
                :finished="finished"
                :offset="200"
                finished-text="没有更多了"
              >
                <van-cell-group class="withDrawCash" v-for="(data,index) in item.data" :key="index">
                  <van-cell
                    :title="(data.method == 1) ? '提现到微信' : '提现到支付宝' "
                    :label="data.created_at"
                    size="large"
                  >
                    <div class>
                      <span class="van-cell__value">-{{data.money}}</span>
                    </div>
                  </van-cell>
                  <div class="across"></div>
                </van-cell-group>
              </van-list>
            </van-pull-refresh>
          </div>
          <div v-else>
            <p class="mt50 tc" style="color:#555;">
              你还没有提现过哦~
              <router-link to="/wallet" style="color:#555;text-decoration:underline;">去提现</router-link>
            </p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import ls from "@/libs/localstore";
import { List, CellGroup, Cell, Tab, Tabs, PullRefresh } from "vant";
import { withDrawCash } from "@/api/wallet";

Vue.use(List)
  .use(CellGroup)
  .use(Cell)
  .use(Tabs)
  .use(Tab)
  .use(List)
  .use(PullRefresh);

export default {
  name: "WithDrawCash",
  components: {
    StatusBar,
    NavBar,
  },
  data() {
    return {
      img: {},
      tab: [
        { title: "已到账", data: [], status: 2, toPage: 1 },
        { title: "提现中", data: [], status: 1, toPage: 1 },
      ],
      tabIndex: 0,
      toPage: 1,
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  computed: {
    withdrawIsNotNull() {
      return this.tab[this.tabIndex].length != 0;
    },
  },
  mounted() {
    this.$toast.loading({
      message: "加载中...",
      duration: 0,
      loadingType: "spinner",
      forbidClick: true,
    });
  },
  methods: {
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.tab[this.tabIndex].toPage = 1;
      this._getData();
    },
    change_tab() {
      this.tab[this.tabIndex].toPage = 1;
      this.tab[this.tabIndex].data=[]
      this.onRefresh();
    },
    //获取数据
    _getData() {
      if (this.refreshing) {
        this.tab[this.tabIndex].data = [];
        this.refreshing = false;
      }
      let params = {
         
        status: this.tab[this.tabIndex].status,
        toPage: this.tab[this.tabIndex].toPage,
        rows: 10,
      };
      withDrawCash(params)
        .then((res) => {
          if (res.errCode == 0 && res.data.length > 0) {
            this.tab[this.tabIndex].data = this.tab[this.tabIndex].data.concat(
              res.data
            );
            this.tab[this.tabIndex].toPage++;
            this.loading = false;
          } else {
            this.finished = true;
          }
          this.$toast.clear();
          this.loading = false;
        })
        .catch((err) => {
          this.$toast.clear();
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #ffffff;
}
.gray {
  background-color: #f6f6f6;
  height: 10px;
  width: 100%;
}
.account {
  width: 375px;
  height: 100px;
  background: linear-gradient(
    270deg,
    rgba(255, 108, 115, 1),
    rgba(255, 85, 149, 1)
  );
  color: rgba(255, 255, 255, 1);
  font-family: PingFang SC;
  padding: 24px 0 27px 18.5px;
  box-sizing: border-box;
  p {
    line-height: 19px;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 14px;
  }
  span {
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
  }
}
.van-tabs--line .van-tabs__wrap {
  height: 50px;
  .van-tab {
    line-height: 50px;
    font-size: 16px;
    color: #333333;
  }
  .van-tab--active {
    color: #ff5050;
  }
  .van-tabs__line {
    width: 33px !important;
    height: 2px;
    top: 43px;
    background: rgba(255, 80, 80, 1);
  }
}
.van-tabs__content {
  .van-cell {
    padding: 18px 19px 15px 19px;
    box-sizing: border-box;
    height: 67.5px;
    position: relative;
    .van-cell__title {
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 1;

      span {
        font-size: 15px;
        color: #333333;
      }
      .van-cell__label {
        margin-top: 8px;
        line-height: 1;
        font-size: 12px;
        color: #6a6a6a;
      }
    }
    .van-cell__value {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .across {
    left: 19px;
    bottom: 0;
    position: absolute;
    width: 356px;
    height: 1px;
    background: rgba(234, 234, 234, 1);
  }
  .van-hairline--top-bottom::after {
    border: none;
  }
}
.van-list__finished-text {
  background-color: #fff;
}
</style>
